.page-activities {
    padding-top: 133px;

    .section {
        &__page-title {
            padding: 100px 0;

            .title {
                color: #1B2E89;
                font-size: 40px;
                letter-spacing: 0;
                line-height: 45px;
                font-weight: 400;
            }
        }

        &__content, &__title {
            position: relative;
            margin-bottom: 100px;

            &:before {
                top: -50px;
                left: -60vh;
                position: absolute;
                content: '';
                width: 100vh;
                height: 100vh;
                border-radius: 50%;
                background: #3D58DB;
                opacity: 0.05;
                z-index: -1;
            }

            .sidebar {
                border-radius: 5px;
                background-color: #FFFFFF;
                box-shadow: 0 2px 30px 0 rgba(0,0,0,0.05);
                padding: 30px;

                &__navigation {
                    display: flex;
                    flex-direction: column;

                    &__link {
                        color: #1B2E89;
                        font-size: 15px;
                        letter-spacing: 0;
                        line-height: 18px;
                        text-decoration: none;
                        margin: 12px 0;
                    }
                }
            }

            .card {
                border: none;
                border-radius: 0;
                margin-bottom: 100px;

                &--expanded {
                    .card {
                        &__excerpt {
                            display: none;
                        }
                        &__content {
                            display: block;
                        }
                    }
                }

                &__title {
                    color: #1B2E89;
                    font-size: 35px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 30px;
                    margin-bottom: 25px;
                }

                &__excerpt {
                    margin-bottom: 20px;

                    p {
                        font-size: 15px;
                        letter-spacing: 0;
                        line-height: 23px;
                    }

                    img {
                        width: 100%;
                        padding: 20px;
                        margin: 20px 0;
                        background-color: rgba(61,88,219,0.05);
                    }
                }

                &__content {
                    display: none;
                    margin-bottom: 20px;

                    p {
                        font-size: 15px;
                        letter-spacing: 0;
                        line-height: 23px;
                    }

                    img {
                        width: 100%;
                        padding: 20px;
                        margin: 20px 0;
                        background-color: rgba(61,88,219,0.05);
                    }
                }

                &__open-link {
                    color: #1B2E89;
                    font-size: 15px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 18px;
                    padding-left: 30px;
                    position: relative;
                    text-decoration: none;

                    &:before {
                        content: "";
                        width: 20px;
                        height: 2px;
                        background: #1B2E89;
                        position: absolute;
                        left: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}