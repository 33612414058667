.page-offices {
    padding-top: 133px;

    @include media-breakpoint-down(sm) {
        padding-top: 75px;
    }

    .section {
        &__page-title {
            padding: 100px 0;

            @include media-breakpoint-down(sm) {
                padding: 50px 0;
            }

            .title {
                color: #1B2E89;
                font-size: 40px;
                letter-spacing: 0;
                line-height: 45px;
                font-weight: 400;
            }
        }

        &__content {
            position: relative;
            margin-bottom: 100px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 50px;
            }

            &:before {
                top: -50px;
                left: -60vh;
                position: absolute;
                content: '';
                width: 100vh;
                height: 100vh;
                border-radius: 50%;
                background: #3D58DB;
                opacity: 0.05;
                z-index: -1;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .card {
                border: none;
                border-radius: 10px;
                margin-bottom: 30px;
                box-shadow: 0 2px 20px 0 rgba(0,0,0,0.06);
                overflow: hidden;

                &__header {
                    height: 250px;
                }

                &__thumbnail {
                    height: 100%;
                    width: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }
                }

                &__map {
                    height: 100%;
                    width: 100%;
                }

                &__content {
                    padding: 30px;
                    padding-bottom: 0;

                    &__group {
                        margin-bottom: 25px;

                        &__label {
                            font-size: 15px;
                            font-weight: 500;
                            letter-spacing: 0;
                            line-height: 20px;
                            margin-bottom: 5px;
                        }

                        &__value {
                            color: #959595;
                            font-size: 15px;
                            letter-spacing: 0;
                            line-height: 19px;

                            .day {
                                width: 100px;
                                display: inline-block;
                            }
                        }
                    }
                }

                &__footer {
                    position: relative;
                    padding: 30px;
                }

                &__label {
                    right: 30px;
                    bottom: 30px;
                    position: absolute;
                    color: #3D58DB;
                    font-size: 15px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 18px;
                    padding: 14px 20px;
                    border-radius: 23px;
                    background-color: rgba(61,88,219,0.1);
                }
            }
        }
    }
}