@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');

@import "custom";
@import "bootstrap";
@import "layout";

@import "pages/home";
@import "pages/activities";
@import "pages/works";
@import "pages/offices";
@import "pages/contact";
@import "pages/default";
@import "pages/folders";
@import "pages/quotations";
@import "pages/contact";
@import "pages/profile";