html, body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
}

.header {
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    padding: 45px 0;
    background: #FFF;
    z-index: 1000;

    @include media-breakpoint-down(sm) {
        padding: 25px 0;
    }

    &.fixed {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.15);
    }

    &.transparent {
        background: transparent;
    }

    &.light {
        .header {
            &__navigation {
                .navigation {
                    &__link {
                        color: #FFFFFF;
                    }
                    &__utils {
                        .user-profile {
                            filter: invert(99%) sepia(0%) saturate(7500%) hue-rotate(66deg) brightness(107%) contrast(100%);
                        }
                    }
                }
            }
        }
    }

    .logo {
        &__wrapper {
            height: 100%;
            display: flex;
            align-items: center;
        }

        &__image {
            max-height: 25px;
            max-width: 200px;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .navigation {
            margin-left: 20px;

            &__utils {
                .navigation {
                    &__link {
                        margin: 5px;
                    }
                    &__dropdown {
                        display: inline-block;
                        position: relative;

                        &:hover {
                            .dropdown {
                                display: block;
                            }
                        }

                        img {
                            height: 43x;
                            width: 43px;
                        }

                        .dropdown {
                            display: none;
                            right: 0;
                            position: absolute;
                            padding-top: 10px;

                            &__inner {
                                background: #FFF;
                                padding: 10px 15px;
                                border: 1px solid #EEE;
                                border-radius: 10px;
                            }
                            &__link {
                                display: block;
                                color: #000;
                                font-weight: 300;
                                margin-bottom: 10px;
                                text-decoration: none;
                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }

                            .separator {
                                margin: 10px 0;
                            }
                        }
                    }
                }
            }

            &__link {
                color: #000;
                margin: 10px 15px;
                font-weight: 300;
                position: relative;

                &.active {
                    font-weight: 500;

                    &:after {
                        content: '•';
                        line-height: 2px;
                        font-size: 1.5em;
                        position: absolute;
                        bottom: -12px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                &:hover {
                    text-decoration: none;
                }

                &--rounded {
                    color: #FFF;
                    background: #3D58DB;
                    border-radius: 25px;
                    padding: 12px 20px;
                    font-weight: 400;
                }

                .user-profile {
                    height: 43px;
                }
            }
        }
    }
}

.footer {
    color: #FFF;
    background: #1B2E89;
    padding: 100px 0;

    @include media-breakpoint-down(sm) {
        padding: 30px 0;
    }

    &__column {
        height: 100%;
        display: flex;
        align-items: flex-end;

        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }

        &--nav {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }

        &__title {
            color: inherit;
            font-size: 18px;
            font-weight: 900;
            margin-bottom: 20px;
        }
    }

    &__logo {
        max-width: 200px;
    }

    &__navigation {
        display: flex;
        flex-direction: column;

        &__link {
            color: inherit;
            opacity: 0.8;
            margin-bottom: 5px;
            text-decoration: none;
            font-weight: 300;
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &--socials {
            .footer__navigation__link {
                opacity: 1;
                margin-right: 15px;
                font-size: 1.5em;

                svg {
                    height: 20px;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &__copyright {
        color: inherit;
        opacity: 0.8;
        font-size: 14px;
    }
}