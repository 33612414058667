.page-home {
    .section {
        &__title {
            color: #1B2E89;
            font-size: 35px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 40px;
            margin-bottom: 20px;

            @include media-breakpoint-down(sm) {
                font-size: 25px;
                line-height: 30px;
            }

            &--big {
                font-size: 65px;
                line-height: 70px;

                @include media-breakpoint-down(sm) {
                    font-size: 45px;
                    line-height: 50px;
                }
            }

            &--white {
                color: #FFF;
            }
        }

        &__subtitle {
            color: #1B2E89;
            font-size: 30px;
            font-weight: 100;
            letter-spacing: 0;
            line-height: 40px;
            margin-bottom: 20px;
        }

        &__content {
            margin-bottom: 40px;

            p {
                font-weight: 300;
                letter-spacing: 0;
                line-height: 22px;
                color: #1B2E89;
            }

            &--white {
                p {
                    color: #FFF;
                }
            }
        }

        &__buttons {
            display: flex;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            .btn {
                color: #1B2E89;
                padding: 12px 25px;
                margin-right: 20px;
                background: #FFFFFF;
                border-radius: 28px;

                @include media-breakpoint-down(sm) {
                    margin-right: 0;
                    margin-bottom: 10px;
                }

                &-outline {
                    color: #1B2E89;
                    background: transparent;
                    border: 1px solid #1B2E89;
                }
            }
        }

        &__landing {
            color: #FFF;
            min-height: calc(100vh - 15px);
            background: #3D58DB;
            padding-top: 123px;
            display: flex;
            align-items: center;

            .section {
                &__title {
                    margin-bottom: 25px;
                }

                &__buttons {
                    margin-bottom: 100px;

                    .btn {
                        color: #3D58DB;

                        &-outline {
                            color: #FFF;
                            background: transparent;
                            border: 1px solid #FFF;
                        }
                    }
                }
            }
        }

        &__introduction {
            padding: 30px 0;

            .row {
                min-height: 80vh;

                @include media-breakpoint-down(md) {
                    min-height: auto;
                }
            }

            .section {
                &__title {
                    max-width: 300px;
                }

                &__image {
                    top: -410px;
                    height: 589px;
                    width: 540px;
                    object-fit: cover;
                    object-position: center;
                    position: absolute;

                    @include media-breakpoint-down(md) {
                        position: static;
                        width: 100%;
                        margin: 20px 0;
                    }
                }
            }

        }

        &__activities {
            padding: 30px 0;
            min-height: calc(100vh - 123px);
            background-color: rgba(27,46,137,0.03);
            display: flex;
            align-items: center;

            @include media-breakpoint-down(md) {
                min-height: auto;
            }

            .section {
                &__slider {
                    // width: 440px;
        
                    .slick-dots li button:before {
                        font-size: 12px;
                        color:#1B2E89;
                    }
        
                    .slick-dots li.slick-active button:before {
                        opacity: 1;
                        color: #1B2E89;
                    }
                    
                }

                &__slide {
                    display: flex!important;
                    flex-wrap: wrap;
    
                    .card {
                        padding: 20px;
                        border-radius: 7px;
                        width: calc(50% - 20px);
                        margin: 10px;
        
                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }
        
                        &__icon {
                            padding: 10px;
                            width: 40px;
                            height: 40px;
                            border-radius: 3px;
                            text-align: center;
                            margin-bottom: 10px;
                        }
    
                        .fa-cog {
                            font-size: 20px;
                        }
        
                        &__title {
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        &__quote {
            padding: 150px 0;

            .section {
                &__title {
                    color: #111213;
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 25px;
                    margin-bottom: 10px;
                }

                &__content {
                    margin-bottom: 100px;

                    p {
                        color: rgba(0,0,0,0.4);
                    }
                }

                &__quotation {
                    blockquote {
                        color: #1B2E89;
                        font-size: 28px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 33px;
                        position: relative;
                        margin-bottom: 0;

                        &:before {
                            content: '"';
                            left: 0;
                            top: -18px;
                            position: absolute;
                            color: #1B2E89;
                            font-size: 80px;
                        }
                    }
                }
            }
        }

        &__works {

            @include media-breakpoint-down(sm) {
                padding-bottom: 30px;
            }

            .colored {
                background: #3D58DB;
                padding-top: 100px;
                padding-bottom: 300px;

                @include media-breakpoint-down(sm) {
                    padding-top: 30px;
                    padding-bottom: 100px;
                }
            }

            .works {
                margin-top: -250px;

                @include media-breakpoint-down(sm) {
                    margin-top: -80px;
                }

                .work {
                    img, video {
                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }
                    }
                }
            }

            .section {
                &__content {
                    p {
                        color: #FFF;
                    }
                }

                &__categories {
                    .navigation {
                        display: flex;
                        justify-content: center;

                        @include media-breakpoint-down(sm) {
                            flex-direction: column;
                        }

                        &__link {
                            color: #FFF;
                            background: rgba(255,255,255,0.15);
                            border-radius: 25px;
                            padding: 12px 20px;
                            margin: 0 8px;
                            font-size: 15px;
                            min-width: 160px;
                            text-align: center;
                            text-decoration: none;

                            &.active {
                                color: #3D58DB;
                                background: #FFF;
                            }

                            @include media-breakpoint-down(sm) {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }

        &__blog {
            padding: 100px 0;

            @include media-breakpoint-down(sm) {
                padding: 30px 0;
            }

            #articles {
                position: relative;

                &:after {
                    top: 50%;
                    right: -50vh;
                    position: absolute;
                    transform: translateY(-50%);
                    content: '';
                    width: 100vh;
                    height: 100vh;
                    border-radius: 50%;
                    background: #3D58DB;
                    opacity: 0.05;
                    z-index: -1;
                }
            }

            .article {
                display: block;
                max-width: 400px;
                border-radius: 5px;
                background-color: #FFFFFF;
                box-shadow: 0 -5px 30px 0 rgba(0,0,0,0.08);
                margin: 30px auto;

                @include media-breakpoint-down(md) {
                    margin: 30px;
                }

                @include media-breakpoint-down(sm) {
                    margin: 30px 20px;
                }

                &__thumbnail {
                    width: 100%;
                    height: 175px;
                    object-fit: cover;
                    object-position: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }

                &__content {
                    font-size: 15px;
                    padding: 30px 40px;
                }

                &__title {
                    color: #111213;
                    font-size: 22px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 22px;
                    margin-bottom: 20px;
                }

                &__excerpt {
                    opacity: 0.8;
                    color: #2F2F2F;
                    letter-spacing: 0;
                    line-height: 24px;
                }

                &__link {
                    padding-left: 30px;
                    color: #1B2E89;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 18px;
                    position: relative;
                    text-decoration: none;

                    &:before {
                        content: "";
                        width: 20px;
                        height: 2px;
                        background: #1B2E89;
                        position: absolute;
                        left: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        &__partners {
            padding: 100px 0;

            @include media-breakpoint-down(sm) {
                padding: 30px 0;
            }

            .section {
                &__title {
                    margin-bottom: 5px;
                }
                &__subtitle {
                    margin-bottom: 40px;
                }
            }

            .partners {
                display: flex;
                flex-wrap: wrap;
            }

            .partner {
                flex: 1 0 19.5%;
                border-radius: 7px;
                background-color: #FFFFFF;
                box-shadow: 0 2px 20px 0 rgba(0,0,0,0.08);
                margin: 20px;
                text-align: center;

                &__link {
                    display: block;
                    padding: 30px;
                }

                &__logo {
                    width: 70px;
                }
            }
        }
    }
}