.page-quotation {
    background: #3D58DB;

    .section {
        display: none;
        min-height: 100vh;
        align-items: center;

        &--end {
            display: inherit;
            min-height: 100vh;
            align-items: center;   
        }

        &__content__end {
            &:before {
                top: -440px;
                left: -30vh;
                position: absolute;
                content: '';
                width: 100vh;
                height: 100vh;
                border-radius: 50%;
                opacity: 0.05;
                background-color: #FFFFFF;
            }
        }

        &__content {

            &:before {
                top: -195px;
                right: -19vh;
                position: absolute;
                content: '';
                width: 120vh;
                height: 120vh;
                border-radius: 50%;
                background: #1B2E89;
                opacity: 20%;
            }
        }

        .step {
            background-color: rgba(216,216,216,0.1);
            color: #FFF;
            padding: 4px 17px;
            font-size: 13px;
            border-radius: 15px;
        }

        &--active {
            display: flex;
        }

        &--start {
            color: #FFF;

            .back {

                &:before {
                    content: url("../../../../../assets/theme/default/img/arrow-back.svg");
                    margin-right: 10px;
                }

                height: 13px;
                width: 50px;
                color: #FFFFFF;
                font-family: Roboto;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 13px;
                text-transform: uppercase;
            }

            .title {
                font-size: 45px;
                letter-spacing: 0;
                line-height: 50px;
                font-weight: 400;
                margin-bottom: 30px;
                margin-top: 65px;
            }

            .description {
                display: block;
                max-width: 500px;
                letter-spacing: 0;
                line-height: 24px;
                margin-bottom: 30px;
                font-weight: 300;
            }

            .description--end {
                margin-top: 35px;
                margin-bottom: 45px;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 22px;
            }
        }

        .breadcrumb {
            padding: 0;
            border-radius: 0;
            background: transparent;
            align-items: flex-end;
            margin-bottom: 50px;

            .item {
                color: #FFF;
                opacity: 0.3;
                font-size: 30px;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 5px;
                margin-right: 35px;

                &--active {
                    opacity: 1;
                    font-size: 50px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 50px;
                    margin-bottom: 0;
                }
            }
        }

        .inner {
            padding: 30px;
            border-radius: 10px;
            background: #4A63DD;
            margin-bottom: 50px;

            .form-control {
                background: transparent;
                border: 0;
                border-bottom: 1px solid #FFF;
                border-radius: 0;
                padding-left: 0;
                padding-bottom: 16px;
                margin-bottom: 40px;
                color: #FFF;
            }

            ::placeholder, label {
                color: #FFF;
            }

            .select2-selection {
                border: none;
            }

            .select2-container {

                @include media-breakpoint-down(md) {
                    width: 100%!important;
                    margin-bottom: 40px;
                }
            }

            .select2-selection__rendered {
                background-color: #4A63DD;
                color: #FFF;
                border: none;
                padding-bottom: 16px;
                border-bottom: 1px solid #fff;
                white-space: initial;
            }

            .select2-dropdown  {
                background-color: #4A63DD;
                color: #FFF;
                width: 13vw;
                border: none;
            }

            .subtitle {
                color: #FFF;
                font-family: Roboto;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 25px;
            }

            #quotation_store_placeholder, #quotation_store label:nth-of-type(1) {
                display: none;
            }

            #quotation_option, #quotation_store {
                
                .radio{
                    display: none;

                    &:checked+label { 
                        background-color: #1B2E89; 
                    } 
                }


                label {
                    padding: 14px 22px;
                    background-color: rgba(255,255,255,0.15);
                    border-radius: 23px;
                    margin-right: 15px;
                    font-family: Roboto;
                    font-size: 15px;
                    letter-spacing: 0;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
        }

        .inner-detail {
            height: calc(100% - 50px);
        }

        .inner--end {
            position: absolute;
            bottom: 0;
            margin-bottom: 27px;

            @include media-breakpoint-down(md) {
                position: inherit;
            }
        }

        .button {
            color: #1B2E89;
            background: #FFF;
            border: 0;
            border-radius: 28px;
            padding: 14px 29px;

            &--prev {
                background: transparent;
                padding-left: 0;
                color: #FFF;
                text-transform: uppercase;

                &:before {
                    content: url("../../../../../assets/theme/default/img/arrow-back.svg");
                    margin-right: 10px;
                }
            }
        }
    }
}