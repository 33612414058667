.page-works {
    padding-top: 133px;

    .section {
        &__page-title {
            padding: 100px 0;

            .title {
                color: #1B2E89;
                font-size: 40px;
                letter-spacing: 0;
                line-height: 45px;
                font-weight: 400;
            }

            .sidebar {

                &__navigation {
                    display: flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;

                    &__link {
                        border: 1px solid #1B2E89;
                        color: #1B2E89;
                        font-size: 15px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 30px;
                        text-decoration: none;
                        margin: 12px 0;
                        padding: 9px 29px;
                        border-radius: 28px;
                        transition: 0.3s;
                        margin: 0px 10px;

                        &:hover {
                            background-color: #1B2E89;
                            color: #FFF;
                        }
                    }

                }
            }
        }

        &__content {
            position: relative;
            margin-bottom: 100px;

            &:before {
                top: -725px;
                right: -13vh;
                position: absolute;
                content: '';
                width: 100vh;
                height: 100vh;
                border-radius: 50%;
                background: #3D58DB;
                opacity: 0.05;
                z-index: -1;
            }
        }

        .card {
            flex-wrap: wrap;
            margin: 15px 0;
            margin-bottom: 30px;
            border-radius: 9px;
            max-height: 350px;

            @include media-breakpoint-down(md) {
                border-radius: inherit;
            }

            &--reverse{
                flex-direction: inherit;
                box-shadow: 0 2px 20px 0 rgba(0,0,0,0.06);
                border: none;
            }
            
            &__content {

                &__slider {
                    position: relative;
                    height: 100%;
                    max-height: 350px;

                    .slick-slider {
                        height: 100%;
                    }
                    
                    .slick-list {
                        border-top-left-radius: 9px;
                        border-bottom-left-radius: 9px;
                        height: 100%;
    
                        @include media-breakpoint-down(md) {
                            border-radius: inherit;
                            height: 100%;
                        }
                    }

                    .slick-track {
                        height: 100%;
                    }

                    .slick-slide {
                        height: 100%;

                        div {
                            height: 100%;
                        }
                    }

                    &__img {
                        object-fit: cover;
                        height: 100%;
                    }

                    .arrows {
                        bottom: 19px;
                        position: absolute;
                        right: 27px;
                        border-radius: 32px;
                        background-color: rgba(0,0,0,0.2);
                        display: flex;
                        padding: 30px 60px;

                        .slick-prev, .slick-next {
                            background-color: #FFF;
                            border-radius: 50%;
                            height: 46px;
                            width: 46px;
                        }
                        
                        .slick-prev {
                            left: 10px;
                            &:before {
                                line-height: inherit;
                                content: url("../../../../../assets/theme/default/img/arrow-prev.svg");
                            }
                        }

                        .slick-next {
                            right: 10px;
                            &:before {
                                line-height: inherit;
                                content: url("../../../../../assets/theme/default/img/arrow-next.svg");
                            }
                        }
                    }

                }

                &__text {
                    padding: 30px 35px;

                    @include media-breakpoint-down(md) {
                        border-radius: inherit;
                    }
                }
            }

            &-title {
                color: #111213;
                font-size: 26px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 30px;
                text-transform: uppercase;
            }

            &__subtitle {
                color: #1B2E89;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 5px;
            }

            &-text {
                color: #111213;
                font-weight: 300;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 23px;
                margin-bottom: 30px;
            }

            &__signature {
                color: #1B2E89;
                font-size: 15px;
                letter-spacing: 0;
                line-height: 23px;
                margin-bottom: 0;
            }
        }
    }
}