.row-centered {
    display: flex;
    flex-flow: wrap;
    align-items: center;
}

.v-align-center {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    flex-direction: column;
}

$theme-colors: (
  "primary": #0074d9
);