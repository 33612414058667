.page-contact {
    min-height: 100vh;
    padding-top: 133px;

    .section {
        &__content {
            padding: 50px 0;
        }

        &__title {
            color: #1B2E89;
            font-weight: 500;
            font-size: 40px;
            letter-spacing: 0;
            line-height: 45px;
        }

        &__subtitle {
            color: #1B2E89;
            font-weight: 300;
            font-size: 40px;
            letter-spacing: 0;
            line-height: 45px;
            margin-bottom: 50px;
        }

        label {
            color: #1B2E89;
        }

        .form-group {
            margin-bottom: 20px;
        }

        .form-control {
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid #1B2E89;
        }

        .button {
            color: #FFF;
            background: #1B2E89;
            border: 0;
            border-radius: 30px;
            padding: 10px 25px;
        }

        &__image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }
}