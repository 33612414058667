.page-profile {
    padding-top: 133px;

    .section {
        &__page-title {
            padding: 100px 0px 28px 0;

            .title {
                color: #1B2E89;
                font-size: 40px;
                letter-spacing: 0;
                line-height: 45px;
                font-weight: 400;
            }
        }

        &__content {
            position: relative;
            margin-bottom: 100px;

            .breadcrumb {
                background: none;
                padding: 0;
                margin: 0;
            }
        
            .breadcrumb-item {
                background-color: #dee2e6;
                border: none;
                color: #1B2E89;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 500;
                margin-left: 10px;
                padding: 10px;
                border-radius: 5px;

                &::before {
                    content: "";
                    padding: 0;
                }

                &:first-of-type {
                    margin-left: 0;
                }

                a {
                    color: #3D58DB;;
                }
            }

            table {
                box-shadow: 0px 0px 16px #d2d2d28a;

                thead tr {
                    background-color: #1B2E89;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    text-transform: uppercase;
                    font-weight: 200;
                    padding: 10px 20px;
                }

                tr {
                    padding: 0 20px;
                }
                
                th {
                    color: #fff;
                    border: none;
                    font-weight: 400;
                    font-size: 13px;
                }
    
                td {
                    color: #1B2E89;
                    text-transform: uppercase;
                    padding: 32px 12px;
                    font-size: 15px;
                    font-weight: 400;

                    a {
                        color: #3D58DB;
                    }
                }
            }

            .table-striped > tbody > tr:nth-child(2n+0), .table-striped > tbody > tr:nth-child(2n+0)  {
                background: #3d58db1a;
                color: #1B2E89;
            }
            
            .table-striped > tbody > tr:nth-child(2n+1), .table-striped > tbody > tr:nth-child(2n+1)  {
                background: #fff;
            }


            .fa-lock {
                color: red;
                margin-right: 10px;
            }

            .fa-arrow-circle-down {
                font-size: 24px;
            }

            .download {
                color: #FFF;
                background: #1B2E89;
                border-radius: 50%;
                padding: 8px 10px;

                &:focus {
                    outline: none;
                }
            }

            &:before {
                top: -725px;
                right: -13vh;
                position: absolute;
                content: '';
                width: 100vh;
                height: 100vh;
                border-radius: 50%;
                background: #3D58DB;
                opacity: 0.05;
                z-index: -1;
            }
        }
    }
}